<template>
  <b-row no-gutters class="mt-4">
    <b-col :cols="cols">
      <calendar-choice
        :timetable="timetable"
        :playground="playground"
        :calendarsListSelection="calendarsListSelection"
        :playgroundTimetables="playgroundTimetables"
        @on:calendar-selected="onCalendarSelected"
      />
    </b-col>
    <b-col :cols="cols">
      <time-zone-list
        :calendar-selection="calendarSelection"
      />
    </b-col>
  </b-row>
</template>
<script>
export default {
  data: () => ({
    calendarSelection: null,
    refresh: false,
  }),
  props: {
    timetable: {
      type: Object,
      default: this
    },
    calendarsListSelection: {
      type: Array,
      default: this
    },
    playgroundTimetables: {
      type: Array,
      default: this
    },
    playground: {
      type: Object,
      default: this
    }
  },
  components: {
    CalendarChoice: () => import('./CalendarChoice'),
    TimeZoneList: () => import('./TimeZoneList')
  },
  computed: {
    cols() {
      return this.$store.getters["layout/getInnerWidth"] >= 990 ? '6' : '12';
    },
  },
  methods: {
    onCalendarSelected(calendar) {
      this.calendarSelection = calendar;
    },
  }
}
</script>
